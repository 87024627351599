
import { defineComponent } from 'vue';
import SignUpForm from '@/components/forms/account/sign-up/SignUpForm.vue';
import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';

export default defineComponent({
  name: 'sign-up-page',
  components: {
    SignUpForm,
    MainLogo,
    GoogleRecaptcha,
  },
});
